import type { App } from 'vue'

const createApp = (app: App) => {
  app.provide('gtmOptions', {
    usercentricsId: '',
    gtmId: 'string',
  })
}

export default createApp
